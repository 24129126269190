import React, { lazy, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Route, Switch } from 'react-router';
import WindowFactory, { WindowType } from '$components/HOC/WindowFactory';
import Page from '$components/system/Page/Page';
import ErrorBoundary from '$components/system/ErrorBoundary';
import { MOBXDefaultProps, RouterEnum } from '$types';
import MobXRouterDecorator from '$components/HOC/MobXRouterDecorator';
import Auth from './providers/Auth.provider';
import NotificationWindow from './NotificationWindow';
import ScrollToTop from '$components/system/ScrollToTop';
import { useRootService } from '$hooks/useRootService';

const Referrer = lazy(() => import(/* webpackChunkName:'Referrer' */'$pages/Referrer'));
const HomePage = lazy(() => import(/* webpackChunkName:'Home' */'$pages/Home'));
const ArticlePage = lazy(() => import(/* webpackChunkName:'Article' */'$pages/Article'));
const CategoriesList = lazy(() => import(/* webpackChunkName:'CategoriesList' */'$pages/CategoriesList'));
const Category = lazy(() => import(/* webpackChunkName:'Category' */'$pages/Category'));
const NewsPage = lazy(() => import(/* webpackChunkName:'NewsPage' */'$pages/News'));
const Profile = lazy(() => import(/* webpackChunkName:'Profile' */'$pages/Profile'));
const Tools = lazy(() => import(/* webpackChunkName:'Tools' */'$pages/Tools'));
const AcademyPro = lazy(() => import(/* webpackChunkName:'AcademyPro' */'$pages/AcademyPro'));
const Other = lazy(() => import(/* webpackChunkName:'Other' */'$pages/Other'));
const MiniCourseOffers = lazy(() => import(/* webpackChunkName:'MiniCourseOffers' */'$pages/MiniCourseOffers'));
const MiniCourseRecord = lazy(() => import(/* webpackChunkName:'MiniCourseRecord' */'$pages/MiniCourseRecord'));
const SwapPage = lazy(() => import(/* webpackChunkName:'Swap' */'pages/Swap'));
const ExchangePage = lazy(() => import(/* webpackChunkName:'Exchange' */'pages/Exchange'));
const TermsOfServicePage = lazy(() => import(/* webpackChunkName:'TermsOfServicePage' */'$pages/TermsOfService'));
const TransactionStatusPage = lazy(() => import(/* webpackChunkName:'TransactionStatusPage' */'$pages/TransactionStatus'));
const AaveCalculator = lazy(() => import(/* webpackChunkName:'AaveCalculator' */'$pages/AaveCalculator'));
const Bootcamp = lazy(() => import(/* webpackChunkName:'Bootcamp' */'$pages/Bootcamp'));

function Router(props: MOBXDefaultProps) {
  const services = useRootService();
  const getPage = (routerProps, Component, type?: any, hideHeader = false) => {
    return (
      <ScrollToTop>
        <Page hideHeader={hideHeader}>
          <Suspense fallback={null}>
            <Component type={type} {...routerProps} />
          </Suspense>
        </Page>
      </ScrollToTop>

    );
  };

  return (
    <BrowserRouter>
      <NotificationWindow />
      <ErrorBoundary throwError={services.AppService.errorListener}>
        <Auth>
          <Switch>
            <Route
              exact
              path={RouterEnum.CATEGORY}
              render={p => getPage(p, CategoriesList)}
            />
            <Route
              exact
              path={RouterEnum.PROFILE}
              render={p => getPage(p, Profile)}
            />
            <Route
              exact
              path={RouterEnum.REFERRER}
              render={p => getPage(p, Referrer)}
            />
            <Route
              exact
              path={RouterEnum.ACADEMYPRO}
              render={p => getPage(p, AcademyPro)}
            />
            <Route
              exact
              path={RouterEnum.TOOLS}
              render={p => getPage(p, Tools)}
            />
            <Route
              exact
              path={RouterEnum.CATEGORY + '/:category_alias'}
              render={p => getPage(p, Category)}
            />
            <Route
              exact
              path={RouterEnum.CATEGORY + '/:category_alias' + '/:article_alias'}
              render={p => getPage(p, ArticlePage)}
            />
            <Route
              exact
              path={RouterEnum.OTHER}
              render={p => getPage(p, Other)}
            />
            <Route exact path={RouterEnum.NEWS} render={p => getPage(p, NewsPage)} />
            <Route exact path={RouterEnum.SWAP} render={p => getPage(p, SwapPage)} />
            <Route exact path={RouterEnum.EXCHANGE} render={p => getPage(p, ExchangePage)} />
            <Route
              exact
              path={RouterEnum.HOME}
              render={p => getPage(p, HomePage)}
            />
            <Route
              exact
              path={RouterEnum.TRANSACTION_STATUS}
              render={p => getPage(p, TransactionStatusPage)}
            />
            <Route
              exact
              path={RouterEnum.AAVE_CALCULATOR}
              render={p => getPage(p, AaveCalculator)}
            />
            {/*<Route*/}
            {/*  exact*/}
            {/*  path={RouterEnum.BOOTCAMP}*/}
            {/*  render={p => getPage(p, Bootcamp, null, true)}*/}
            {/*/>*/}

            {/*<Route*/}
            {/*  exact*/}
            {/*  path={RouterEnum.BOOTCAMP_LIVE}*/}
            {/*  render={p => getPage({ ...p, live: true }, Bootcamp, null, true)}*/}
            {/*/>*/}
            {/*<Route*/}
            {/*  exact*/}
            {/*  path={RouterEnum.BOOTCAMP_TEST}*/}
            {/*  render={p => getPage({ ...p, live: false, forcePrice: 1 }, Bootcamp, null, true)}*/}
            {/*/>*/}
            {/*<Route*/}
            {/*  exact*/}
            {/*  path={RouterEnum.BOOTCAMP_9900}*/}
            {/*  render={p => getPage({ ...p, live: true, spec: true }, Bootcamp, null, true)}*/}
            {/*/>*/}
            <Route exact path={RouterEnum.TERMS_OF_SERVICE} render={p => getPage(p, TermsOfServicePage)} />
            <Route
              exact
              path={RouterEnum.MINI_COURSE_OFFERS_FOR_MONDAY}
              render={p => getPage({ weekDayStart: 1, ...p }, MiniCourseOffers, null, true)}
            />
            <Route
              exact
              path={RouterEnum.MINI_COURSE_OFFERS_FOR_TUESDAY}
              render={p => getPage({ weekDayStart: 2, ...p }, MiniCourseOffers, null, true)}
            />
            <Route
              exact
              path={RouterEnum.MINI_COURSE_OFFERS_FOR_WENDNESDAY}
              render={p => getPage({ weekDayStart: 3, ...p }, MiniCourseOffers, null, true)}
            />
            <Route
              exact
              path={RouterEnum.MINI_COURSE_OFFERS_FOR_THURSDAY}
              render={p => getPage({ weekDayStart: 4, ...p }, MiniCourseOffers, null, true)}
            />
            <Route
              exact
              path={RouterEnum.MINI_COURSE_OFFERS_FOR_FRIDAY}
              render={p => getPage({ weekDayStart: 5, ...p }, MiniCourseOffers, null, true)}
            />
            <Route
              exact
              path={RouterEnum.MINI_COURSE_OFFERS_FOR_SATURDAY}
              render={p => getPage({ weekDayStart: 6, ...p }, MiniCourseOffers, null, true)}
            />
            <Route
              exact
              path={RouterEnum.MINI_COURSE_OFFERS_FOR_SUNDAY}
              render={p => getPage({ weekDayStart: 0, ...p }, MiniCourseOffers, null, true)}
            />
            <Route
              exact
              path={RouterEnum.SPECIAL_OFFERS}
              render={p => getPage({ isSpecialOffer: true, weekDayStart: 6, ...p }, MiniCourseOffers, null, true)}
            />
            <Route
              exact
              path={RouterEnum.MINI_COURSE_RECORD_FOR_MONDAY}
              render={p => getPage({ weekDayStart: 1, ...p }, MiniCourseRecord, null, true)}
            />
            <Route
              exact
              path={RouterEnum.MINI_COURSE_RECORD_FOR_TUESDAY}
              render={p => getPage({ weekDayStart: 2, ...p }, MiniCourseRecord, null, true)}
            />
            <Route
              exact
              path={RouterEnum.MINI_COURSE_RECORD_FOR_WENDNESDAY}
              render={p => getPage({ weekDayStart: 3, ...p }, MiniCourseRecord, null, true)}
            />
            <Route
              exact
              path={RouterEnum.MINI_COURSE_RECORD_FOR_THURSDAY}
              render={p => getPage({ weekDayStart: 4, ...p }, MiniCourseRecord, null, true)}
            />
            <Route
              exact
              path={RouterEnum.MINI_COURSE_RECORD_FOR_FRIDAY}
              render={p => getPage({ weekDayStart: 5, ...p }, MiniCourseRecord, null, true)}
            />
            <Route
              exact
              path={RouterEnum.MINI_COURSE_RECORD_FOR_SATURDAY}
              render={p => getPage({ weekDayStart: 6, ...p }, MiniCourseRecord, null, true)}
            />
            <Route
              exact
              path={RouterEnum.MINI_COURSE_RECORD_FOR_SUNDAY}
              render={p => getPage({ weekDayStart: 0, ...p }, MiniCourseRecord, null, true)}
            />
            <Route exact path={RouterEnum.SIGNIN} render={p => <WindowFactory type={WindowType.SignIn} />} />
            <Route exact path={RouterEnum.SIGNUP} render={p => <WindowFactory type={WindowType.SignUp} />} />
            <Route exact path={RouterEnum.CONFIRM_USER} render={p => <WindowFactory type={WindowType.ConfirmUser} />} />
            <Route
              exact
              path={RouterEnum.CONFIRM_PASSWORD}
              render={p => <WindowFactory type={WindowType.ConfirmPassword} />}
            />
            <Route
              exact
              path={RouterEnum.RESET_PASSWORD}
              render={p => <WindowFactory type={WindowType.ResetPassword} />}
            />
            <Route exact path="*" render={p => <WindowFactory type={WindowType.NotFoundPage} />} />
          </Switch>
        </Auth>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

export default MobXRouterDecorator(Router, false);
